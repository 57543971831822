import { Contract, Mapping, MetadataType } from "../../types/report";
import instanceRequest, { Request } from "../../Request";
import { Template } from "../../types/report";
import { AxiosResponse } from "axios";

class ReportRepository {
  private axiosService: Request;

  public constructor(axiosService: Request) {
    this.axiosService = axiosService;
  }

  getAllContracts = (): Promise<AxiosResponse<Contract[]>> => {
    return this.axiosService.get("/api/heimdall/contract", false, true);
  };

  getTemplates = (contractId: string): Promise<AxiosResponse<Template[]>> => {
    return this.axiosService.get<Template[]>(`/api/contract/${contractId}/templates`, false, true);
  };

  getTemplate = (contractId: string, templateName: string): Promise<AxiosResponse<Template>> => {
    return this.axiosService.get<Template>(`/api/contract/${contractId}/templates/${templateName}`, false, true);
  };

  downloadTemplate = (contractId: string, templateName: string) => {
    return this.axiosService.get(`/api/contract/${contractId}/templates/${templateName}/download`, false, true, {
      responseType: "blob",
    });
  };
  getMapping = (contractId: string, templateName: string): Promise<AxiosResponse<Mapping>> => {
    return this.axiosService.get(`/api/contract/${contractId}/templates/${templateName}/mapping`, false, true);
  };
  editMapping = (contractId: string, templateName: string, data: Mapping) => {
    return this.axiosService.put(`/api/contract/${contractId}/templates/${templateName}/mapping`, data);
  };
  editTemplateMetadata = (contractId: string, templateName: string, data: MetadataType) => {
    return this.axiosService.put(`/api/contract/${contractId}/templates/${templateName}/metadata`, { "templateMetadatas": data, "name": templateName });
  };
  uploadModel(contractId: string, file: File) {
    const formData = new FormData();
    formData.append("file", file);
    return this.axiosService.post(`/api/contract/${contractId}/templates`, formData, {});
  }
}
export default new ReportRepository(instanceRequest);
