import instanceRequest, { Request } from "../../Request";
import { CreateBatchMail, CreateMail, MailType, Template } from "../../types/mail.type";
import { AxiosResponse } from "axios";

class MailRepository {
  private axiosService: Request;

  public constructor(axiosService: Request) {
    this.axiosService = axiosService;
  }

  getMails(surveyId: string, contractId: string) {
    return this.axiosService.get<MailType[]>(`/api/mails/contract/${contractId}?operationId=${surveyId}`);
  }

  download(mail: MailType) {
    const url = `/api/mails/${mail.id}/contract/${mail.contractId}/download`;

    return this.axiosService.getBlob(url, false);
  }

  downloadLastMails(surveys: string[] = []) {
    const url = `/api/surveys/export/last-mail`;
    return this.axiosService.getBlob(url, false, {
      params: { "surveys": surveys }
    });
  }

  createMail(surveyId: string, contractId: string, data: CreateMail) {
    const url = `/api/mails/contract/${contractId}/operation/${surveyId}`;

    return this.axiosService.post(url, data);
  }

  createBatchMail(contractId: string, data: CreateBatchMail[]) {
    const url = `/api/mails/contract/${contractId}`;

    return this.axiosService.post(url, data);
  }

  getTemplates(contractId: string): Promise<AxiosResponse<Template[]>> {
    return this.axiosService.get<Template[]>(`/api/contract/${contractId}/templates`);
  }
}

export default new MailRepository(instanceRequest);
