import { SzTab } from "@suezenv/react-theme-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AssociationTab from "./association/AssociationTab";
import MigrationTab from "./migration/migrationTab";
import ReportTab from "./report/ReportTab";
import SendMailConfigTab from "./sendMailConfig/SendMailConfigTab";

export const Params: React.FC = () => {
  const { t } = useTranslation("setting");
  const [tab, setTab] = useState("association-tab");

  const selectTabHandle = (tab: string) => {
    setTab(tab);
  };
  return (
    <SzTab activeKey={tab} id="setting-tab" selectTabHandle={selectTabHandle.bind(this)}>
      <SzTab.SzTabContent eventKey="association-tab" title={t("association.tab-title")}>
        <AssociationTab />
      </SzTab.SzTabContent>
      <SzTab.SzTabContent eventKey="report-tab" title={t("report.tab-title")}>
        <ReportTab />
      </SzTab.SzTabContent>
      <SzTab.SzTabContent eventKey="migration-tab" title={t("migration.tab-title")}>
        <MigrationTab />
      </SzTab.SzTabContent>
      <SzTab.SzTabContent eventKey="config-tab" title={t("sendMailConfig.tab-title")}>
        <SendMailConfigTab />
      </SzTab.SzTabContent>
    </SzTab>
  );
};

export default Params;
