declare global {
    interface Window {
        REACT_APP_API_HOST: string;
    }
}

// use process.env in dev instead window
export const config = {
    apiUrl: window.REACT_APP_API_HOST || process.env.REACT_APP_API_HOST,
};


