import React from "react";
import { useTranslation } from "react-i18next";
import { SzButton, SzIcon, SzTypographie } from "@suezenv/react-theme-components";
import { MailType } from "../../../../../services/types/mail.type";
import MailRepository from "../../../../../services/api/repository/mail.repository";
import fileDownload from "js-file-download";
import { dateFormat } from "../../../../../services/utils/date.formatter";

interface Props {
  mail: MailType;
}

const MailItem: React.FC<Props> = ({ mail }) => {
  const { t } = useTranslation(["survey.view"]);
  const download = () => {
    MailRepository.download(mail).then((response) => {
      fileDownload(response.data, `${mail.filename}.docx`);
    });
  };

  return (
    <div className="row mb-4 mx-4 mt-4 justify-content-between border-bottom">
      <div className="col-3">
        <div className="row h-100">
          <div className="col-auto px-1 my-auto">
            <SzIcon variant="line" icon="envelope" />
          </div>
          <div className="col-9 px-0">
            <SzTypographie variant="caption" weight="light" className="long-text mb-0">
              {t("details.mail.mailName")}
            </SzTypographie>
            <SzTypographie variant="caption" weight="medium" className="long-text mb-0">
              {mail.templateName}
            </SzTypographie>
          </div>
        </div>
      </div>
      <div className="col-2 m-0 p-0 has-border">
        <div className="row m-0 p-0 h-100">
          <div className="col-auto px-1 my-auto">
            <SzIcon variant="line" icon="people-man-1" />
          </div>
          <div className="col-9 px-0">
            <SzTypographie variant="caption" weight="light" className="long-text mb-0">
              {t("details.mail.generatedBy")}
            </SzTypographie>
            <SzTypographie variant="caption" weight="medium" className="long-text mb-0">
              {mail.owner.lastName} {mail.owner.firstName}
            </SzTypographie>
          </div>
        </div>
      </div>
      <div className="col-2 m-0 p-0">
        <div className="row m-0 p-0 h-100">
          <div className="col-auto px-1 my-auto">
            <SzIcon variant="line" icon="send-email-1" />
          </div>
          <div className="col-7 px-0">
            <SzTypographie variant="caption" weight="light" className="long-text mb-0">
              {t("details.mail.recipient")}
            </SzTypographie>
            <SzTypographie variant="caption" weight="medium" className="long-text mb-0">
              {mail.contact.firstName} {mail.contact.name}
            </SzTypographie>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="row m-0 p-0 h-100">
          <div className="col-auto px-1 my-auto">
            <SzIcon variant="line" icon="time-clock-hand-1" />
          </div>
          <div className="col px-0">
            <SzTypographie variant="caption" weight="light" className="long-text mb-0">
              {t("details.mail.generatedAt")}
            </SzTypographie>
            <SzTypographie variant="caption" weight="medium" className="long-text mb-0">
              {dateFormat(mail.generationDate, "DD/MM/YY HH:mm")}
            </SzTypographie>
          </div>
        </div>
      </div>
      {/* 
      {mail.comment && (
        <div className="col">
          <div className="row m-0 p-0 h-100">
            <div className="col-auto px-1 my-auto">
              <SzIcon variant="line" icon="messages-bubble-edit" />
            </div>
            <div className="col px-0">
              <SzTypographie variant="caption" weight="light" className="long-text mb-0">
                {t("details.mail.comment")}
              </SzTypographie>
              <SzTypographie variant="caption" weight="medium" className="long-text mb-0">
                {mail.comment}
              </SzTypographie>
            </div>
          </div>
        </div>
      )} */}
      <div className="col-auto p-0 mx-0 has-border">
        <SzButton variant="tertiary" icon="common-file-text-download" onClick={download} />
      </div>
    </div>
  );
};

export default MailItem;
